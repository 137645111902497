import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  url = environment.url;
  

  constructor(private http:HttpClient, private router:Router) { 
  }

 
  packagelist():Observable<any>{
    return this.http.get(this.url + '/admin/package/all')
  }
 

 

  updatepackage(data):Observable<any>{
    return this.http.post(this.url + '/admin/package/update', data)
  }
  addpackage(data):Observable<any>{
    return this.http.post(this.url + '/admin/package/create', data)
  }

  deletepackage(id):Observable<any>{
    let data = {
      id: id
    }

    return this.http.post(this.url + '/admin/package/delete',data)
  }

  

  

 
}
