import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatagoryService {
  url = environment.url;
  cid;

  constructor(private http: HttpClient, private router: Router) {
    this.cid = localStorage.getItem('cid');
  }

  addicon(data): Observable<any> {
    return this.http.post(this.url + '/admin/uploadImage', data)
  }

  addcatagory(data): Observable<any> {
    return this.http.post(this.url + '/category/create', data)
  }
  getallcatagory(): Observable<any> {
    return this.http.get(this.url + '/category/get')
  }

  getAllTask(): Observable<any> {
    return this.http.get(this.url + '/task/getAllTask')
  }

  getallcurrencies(): Observable<any> {
    return this.http.get(this.url + '/user/getcurrencies')
  }

  delete(id, data): Observable<any> {
    return this.http.post(this.url + '/category/delete/' + id, data)
  }

  maincategories(): Observable<any> {
    return this.http.get(this.url + '/admin/allowed/maincategories')
  }

  getcatagorybyid(id): Observable<any> {
    return this.http.get(this.url + '/catagory/getbyid/' + id)
  }

  updatecatagory(data): Observable<any> {
    return this.http.post(this.url + '/category/update/', data)
  }

  catagorycount(): Observable<any> {
    return this.http.get(this.url + '/catagory/getcatagorycount')
  }

}
