export const environment = {
  production: true,
  // id: localStorage.getItem('cid'),


  // url: 'https://api.aanyapp.com'   // live api

  // local url
  // url: 'http://192.168.1.20:3010',
  // storageUrl: 'http://192.168.1.20:3010/uploads',

  //  live url
  url: 'https://task-api.aanyapp.com',
  storageUrl: 'https://task-api.aanyapp.com/uploads',

  country: 'All',
  AWSCF: "https://ddt7qe4719p02.cloudfront.net/",
  S3: "https://aany-app.s3.ap-south-1.amazonaws.com/",

  // firebaseConfig: {
  //   apiKey: "AIzaSyAalw1rMgU1jOHzi9hJB2jhmyiCC8IRkv0",
  //   authDomain: "aany-4c418.firebaseapp.com",
  //   databaseURL: "https://aany-4c418.firebaseio.com",
  //   projectId: "anytask-786",
  //   storageBucket: "aany-4c418.appspot.com",
  //   messagingSenderId: "672473878375",
  //   appId: "1:672473878375:web:1dba66016f8c0b938029c2",
  //   measurementId: "G-PQ587C9N8Y"
  // },



  firebaseConfig: {
    apiKey: "AIzaSyDlV7WYzyWBMdr9K-YD5QMyojfIo-6RkGY",
    authDomain: "anytask-786.firebaseapp.com",
    projectId: "anytask-786",
    storageBucket: "anytask-786.appspot.com",
    messagingSenderId: "1017919138142",
    appId: "1:1017919138142:web:aae1e186fb32801fc9c7cf",
    measurementId: "G-YPG2DE0QP9"
  }
};
