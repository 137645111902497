import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  url = environment.url;
  cid;

  constructor(private http:HttpClient, private router:Router) { 
    this.cid = localStorage.getItem('cid');
  }

 
  orderlist():Observable<any>{
    return this.http.get(this.url + '/admin/orderedqr/all')
  }
 

 

  updateorder(data):Observable<any>{
    return this.http.post(this.url + '/admin/orderedqr/update', data)
  }

  deleteorder(id):Observable<any>{
    let data = {
      id: id
    }

    return this.http.post(this.url + '/admin/orderedqr/delete',data)
  }

  scanlist():Observable<any>{
    return this.http.get(this.url + '/admin/scannedall')
  }

  

  

 
}
