import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  url = environment.url;
  cid;
  perm;
  constructor(private http:HttpClient, private router:Router) {
    this.cid = localStorage.getItem('cid');

   }

  

  addrole(data):Observable<any>{
    return this.http.post(this.url + '/role/new_role/'+localStorage.getItem('cid'),data)
  }

  updaterole(data):Observable<any>{
    return this.http.post(this.url + '/role/update_role',data)
  }

  getrole(id):Observable<any>{
    return this.http.get(this.url + '/role/getbyid/'+id)
  }

  

  deleterole(id):Observable<any>{
    return this.http.get(this.url + '/role/delete/'+id)
  }

  get_features():Observable<any>{
    return this.http.get(this.url + '/role/get_feature')

  }

  get_roles():Observable<any>{
    return this.http.get(this.url + '/role/get_allroles/'+localStorage.getItem('cid'))

  }

  isRole(role, per) {
  
    this.perm = JSON.parse(localStorage.getItem('perm'));
    for (let i = 0; i < this.perm.length; i++) {
      // console.log('working')

        if (this.perm[i].feature.slug === role) {
          // console.log('true')

            if (this.perm[i].full === true) {
              // console.log('true')
                return true;
            }
            return this.perm[i][per];
        }
    }
}

 
}
