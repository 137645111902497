import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoginService } from './service/login.service';
import { GuardGuard } from './guard/guard.guard';
import { TokenInterceptorService } from './service/token-interceptor.service';
import { UserService } from './service/user.service';
import { HouseService } from './service/house.service';
import { ChecklistService } from './service/checklist.service';
import { AdminService } from './service/admin.service';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoginGuard } from './guard/login.guard';
// import { NgSelect2Module } from 'ng-select2';
import { DragulaModule } from 'ng2-dragula';
import { TicketService } from './service/ticket.service';
import { ReminderService } from './service/reminder.service';
import { SettingService } from './service/setting.service';
import { ExcelService } from './service/excel.service';
import { RoleService } from './service/role.service';
import { SuperService } from './service/super.service';
import { CatagoryService } from './service/catagory.service';
import { ShopService } from './service/shop.service';
import { ProductService } from './service/product.service';
import { VariablesService } from './service/variables.service';
import { OrdersService } from './service/orders.service';
import { PackageService } from './service/package.service';
import { PromoService } from './service/promo.service';
import { SupportService } from './service/support.service';

//I keep the new line

//I keep the new line
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DragulaModule.forRoot(),
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      tapToDismiss: true,
      progressBar: true,
      newestOnTop: true,
      timeOut: 2000,
    }),
    FooterModule,
    FixedPluginModule,
  ],
  providers: [LoginService, OrdersService, SupportService, PromoService, PackageService, VariablesService, SuperService, ProductService, GuardGuard, LoginGuard, UserService, HouseService, ChecklistService, AdminService, TicketService, ReminderService, SettingService, ExcelService, RoleService, CatagoryService, ShopService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
