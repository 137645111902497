import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  url = environment.url;
  constructor(private http: HttpClient, private router: Router) { }

  setting_list(): Observable<any> {
    return this.http.get(this.url + '/setting/get_setting')
  }



  update_aboutinfo(data): Observable<any> {
    return this.http.post(this.url + '/setting/update_aboutinfo', data)
  }



  add_aboutinfo(data): Observable<any> {
    return this.http.post(this.url + '/setting/create_aboutinfo', data)
  }

  update_logo(data): Observable<any> {
    return this.http.post(this.url + '/setting/update_logo', data)
  }

  add_logo(data): Observable<any> {
    return this.http.post(this.url + '/setting/create_logo', data)
  }



  // neww

  update_setting(data): Observable<any> {
    return this.http.post(this.url + '/admin/setting/update', data)
  }

  getalldata(): Observable<any> {
    return this.http.get(this.url + '/admin/setting/all')
  }

  packagelist(id): Observable<any> {
    return this.http.get(this.url + '/shopPackage/allByManagerId/' + id)
  }

  packagelistbycountry(id): Observable<any> {
    return this.http.get(this.url + '/shopPackage/allByCountryId/' + id)
  }

  addpackage(data): Observable<any> {
    return this.http.post(this.url + '/shopPackage/create/', data)
  }

  updatepackage(id, data): Observable<any> {
    return this.http.post(this.url + '/shopPackage/update/' + id, data)
  }


  createCkEditor(data): Observable<any> {
    return this.http.post(this.url + '/settings/create/' + data._id, data)
  }

  get() {
    return this.http.get(this.url + '/settings/app/get')
  }


}
