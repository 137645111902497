import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuperService {
  url = environment.url;
  constructor(private http: HttpClient, private router: Router) { }

  adminlist(): Observable<any> {
    return this.http.get(this.url + '/super/getalladmin')
  }

  addadmin(data): Observable<any> {
    return this.http.post(this.url + '/super/register', data)
  }

  getAdminDetails(id): Observable<any> {
    return this.http.get(this.url + '/super/getbyid/' + id)
  }

  updateadmin(id, data): Observable<any> {
    return this.http.post(this.url + '/super/update/' + id, data)
  }
  updatecountry(id, data): Observable<any> {
    return this.http.post(this.url + '/country/update/' + id, data)
  }

  deleteadmin(id): Observable<any> {
    return this.http.get(this.url + '/super/delete/' + id)
  }
  companylist(): Observable<any> {
    return this.http.get(this.url + '/super/getallcompany')
  }
  countrylist(): Observable<any> {
    return this.http.get(this.url + '/country/get')
  }

  addcompany(data): Observable<any> {
    return this.http.post(this.url + '/super/register_company', data)
  }

  getcompanyDetails(id): Observable<any> {
    return this.http.get(this.url + '/super/getbyid_company/' + id)
  }

  updatecompany(id, data): Observable<any> {
    return this.http.post(this.url + '/super/update_company/' + id, data)
  }

  deletecompany(id): Observable<any> {
    return this.http.get(this.url + '/super/delete_company/' + id)
  }

  supercount(): Observable<any> {
    return this.http.get(this.url + '/super/getsupercount')
  }
  companycount(): Observable<any> {
    return this.http.get(this.url + '/super/getcompanycount')
  }



}
