import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {

  url = environment.url;
  cid;
  
  constructor(private http:HttpClient, private router:Router) { 
    this.cid = localStorage.getItem('cid');

  }

  reminderlist():Observable<any>{
    return this.http.get(this.url + '/reminder/getallreminder/'+localStorage.getItem('cid'))
  }
  deletereminder(id):Observable<any>{
    return this.http.get(this.url + '/reminder/delete/'+id)
  }
  
  add_reminder(data):Observable<any>{
    
    return this.http.post(this.url + '/reminder/addreminder/'+localStorage.getItem('cid'),data)
  }

  getreminderdetail(id):Observable<any>{
    return this.http.get(this.url + '/reminder/getbyid/'+id)
  }
  update_reminder(data):Observable<any>{
    return this.http.post(this.url + '/reminder/update',data)
  }
  remindercount():Observable<any>{
    return this.http.get(this.url + '/reminder/getremindercount/'+localStorage.getItem('cid'))
  }
}