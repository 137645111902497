import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HouseService { 
  url = environment.url;
  cid;

 
  constructor(private http:HttpClient, private router:Router) {
    this.cid = localStorage.getItem('cid');

   }

  houselist():Observable<any>{
    return this.http.get(this.url + '/house/getallhousee/'+localStorage.getItem('cid'))
  }
  housecount():Observable<any>{
    return this.http.get(this.url + '/house/gethousecount/'+localStorage.getItem('cid'))
  }

  addhouse(data):Observable<any>{
    return this.http.post(this.url + '/house/addhouse/'+localStorage.getItem('cid'),data)
  }

  getHouseDetails(id):Observable<any>{
    return this.http.get(this.url + '/house/gethouse/'+id)
  }

  updatehouse(id,data):Observable<any>{
    return this.http.post(this.url + '/house/edit/'+id, data)
  }

  updatesequence(data):Observable<any>{
    return this.http.post(this.url + '/house/update', data)
  }

  deletehouse(id):Observable<any>{
    return this.http.get(this.url + '/house/delete/'+id)
  }

  buyerlist():Observable<any>{
    return this.http.get(this.url + '/buyer/getallbuyer/'+localStorage.getItem('cid'))
  }

  approve_transfer(id):Observable<any>{
    return this.http.get(this.url + '/buyer/approve/'+id)
  }

  decline_transfer(id):Observable<any>{
    return this.http.get(this.url + '/buyer/decline/'+id)
  }
  getdataexcel(data):Observable<any>{
    return this.http.post(this.url + '/house/exceldata/'+localStorage.getItem('cid'),data)
  }

}
