import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { SettingService } from 'app/service/setting.service';
import { RoleService } from 'app/service/role.service';


export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  slug: string;
  entity: string;
}

export var ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '', slug: 'dashboard', entity: '' },
  { path: '/users', title: 'Users', icon: 'nc-single-02', class: '', slug: 'user', entity: '' },
  { path: '/houses', title: 'Houses', icon: 'nc-bank', class: '', slug: 'house', entity: '' },
  { path: '/checklist', title: 'Checklists', icon: 'nc-tile-56', class: '', slug: 'checklist', entity: '' },
  { path: '/ticket', title: 'Tickets', icon: 'nc-book-bookmark', class: '', slug: 'ticket', entity: '' },
  { path: '/reminder', title: 'Reminders', icon: 'nc-calendar-60', class: '', slug: 'reminder', entity: '' },
  { path: '/admin', title: 'Admins', icon: 'nc-circle-10', class: '', slug: 'admin', entity: '' },
  { path: '/approval', title: 'Approvals', icon: 'nc-check-2', class: '', slug: 'approval', entity: '' },
  { path: '/rolemanagement', title: 'Role Management', icon: 'nc-single-02', class: '', slug: 'rolemanagement', entity: '' },
  { path: '/companies', title: 'Companies', icon: 'nc-app', class: '', slug: '', entity: '' },
  { path: '/superadmin', title: 'Super Admin', icon: 'nc-app', class: '', slug: '', entity: '' },
  { path: '/setting', title: 'Settings', icon: 'nc-settings-gear-65', class: '', slug: '', entity: '' },
  { path: '/about', title: 'About', icon: 'nc-app', class: '', slug: '', entity: '' },
];

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {

  ROUTES: RouteInfo[] = [
    { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '', slug: 'dashboard', entity: '' },
    { path: '/catagories', title: 'Catagories', icon: 'nc-single-02', class: '', slug: 'cata', entity: '' },
    { path: '/users', title: 'Users', icon: 'nc-single-02', class: '', slug: 'user', entity: '' },
    { path: '/admin', title: 'Admins', icon: 'nc-tile-56', class: '', slug: 'product', entity: '' },
    { path: '/task', title: 'Tasks', icon: 'nc-tile-56', class: '', slug: 'product', entity: '' },
    { path: '/report', title: 'Report', icon: 'nc-settings-gear-65', class: '', slug: '', entity: '' },
    { path: '/user', title: 'Profile', icon: 'nc-settings-gear-65', class: '', slug: 'service', entity: '' },
    { path: '/adminnotification', title: 'Notification', icon: 'nc-settings-gear-65', class: '', slug: 'notification', entity: '' },
    { path: '/setting', title: 'Settings', icon: 'nc-settings-gear-65', class: '', slug: '', entity: '' },
    // { path: '/saleman',                    title: 'Sales Team',            icon: 'nc-single-02', class: '', slug: 'sales', entity: '' },
    // { path: '/shops',                    title: 'Shops',            icon: 'nc-bank', class: '', slug: 'shop', entity: '' },
    // { path: '/orderlist',                 title: 'Orderlist',         icon: 'nc-tile-56', class: '', slug: 'order', entity: '' },
    // { path: '/scanlist',                 title: 'Scanlist',         icon: 'nc-tile-56', class: '', slug: 'product', entity: '' },
    // { path: '/variables', title: 'Variables', icon: 'nc-book-bookmark', class: '', slug: 'variable', entity: '' },
    // { path: '/orderedQr',                title: 'Ordered QR',       icon: 'nc-calendar-60', class: '', slug: 'ordered', entity: '' },
    // { path: '/promo', title: 'Promo', icon: 'nc-app', class: '', slug: '', entity: '' },
    // { path: '/packages', title: 'Packages', icon: 'nc-app', class: '', slug: '', entity: '' },
    // { path: '/support', title: 'Customer Support', icon: 'nc-settings-gear-65', class: '', slug: '', entity: '' },
    // { path: '/review', title: 'Review', icon: 'nc-settings-gear-65', class: '', slug: 'review', entity: '' },
    // { path: '/appointment',                  title: 'Appointment',         icon: 'nc-settings-gear-65', class: '', slug: 'appointment', entity: '' },
    // { path: '/customNotification', title: 'Custom Notification', icon: 'nc-settings-gear-65', class: '', slug: 'customNotification', entity: '' },
    // { path: '/subCategory',                  title: 'Sub Category',         icon: 'nc-settings-gear-65', class: '', slug: 'subCategory', entity: '' },

  ];
  url = environment.url;

  img: any;
  no_logo = true;
  setting_data = [];

  super: boolean;

  constructor(

    private _service: SettingService,
    public role_check: RoleService
  ) {
    // this.get_logo();


    // console.log(this.super);

  }

  public menuItems: any[];
  ngOnInit() {
    this.menuItems = this.ROUTES.filter(menuItem => menuItem);

  }

  get_logo() {
    this._service.setting_list().subscribe(data => {
      // console.log(data);
      this.setting_data = data;
      if (data.length > 0) {
        if (data[0].logo == null || data[0].logo == '') {
          this.no_logo = true;
        } else {

          this.img = this.url + '/' + data[0].logo;

          // console.log('i m here');
          this.no_logo = false
        }
      } else {
        this.no_logo = true;
      }

    })
  }
  emitter() {
    // console.log('emitter is here');
  }
}
