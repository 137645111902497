import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  url = environment.url;


  constructor(private http: HttpClient, private router: Router) {
  }

  supportlist(): Observable<any> {
    return this.http.get(this.url + '/admin/contactUs/all')
  }


  reportlist(): Observable<any> {
    return this.http.get(this.url + '/report/get')
  }

  reviewlist(): Observable<any> {
    return this.http.get(this.url + '/admin/review/all')
  }

  reviewlistbyshop(id): Observable<any> {
    let data = {
      shopId: id
    }
    return this.http.post(this.url + '/admin/review/shop', data)
  }



  readsupport(data): Observable<any> {
    return this.http.post(this.url + '/admin/contactUs/read', data)
  }
  readreport(data): Observable<any> {
    return this.http.post(this.url + '/admin/report/read', data)
  }
  addsupport(data): Observable<any> {
    return this.http.post(this.url + '/admin/support/create', data)
  }



  updatesupport(data): Observable<any> {
    return this.http.post(this.url + '/admin/support/update', data)
  }

  deletesupport(id): Observable<any> {
    let data = {
      id: id
    }
    return this.http.post(this.url + '/admin/support/delete', data)
  }
  updatereview(data): Observable<any> {
    return this.http.post(this.url + '/admin/review/update', data)
  }

  deletereview(id): Observable<any> {
    let data = {
      id: id
    }
    return this.http.post(this.url + '/admin/review/delete', data)
  }






}
