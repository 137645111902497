import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  url = environment.url;
  cid;

  
  constructor(private http:HttpClient, private router:Router) { 
    this.cid = localStorage.getItem('cid');

  }

  ticketlist():Observable<any>{
    return this.http.get(this.url + '/ticket/getallticket/'+ localStorage.getItem('cid'))
  }
  deleteticket(id):Observable<any>{
    return this.http.get(this.url + '/ticket/delete/'+id)
  }
  upload_image(data):Observable<any>{
    console.log(data);
    return this.http.post(this.url + '/upload/imgupload',data)
  }
  add_ticket(data):Observable<any>{
    
    return this.http.post(this.url + '/ticket/add_ticket/'+localStorage.getItem('cid'),data)
  }

  getticketdetail(id):Observable<any>{
    return this.http.get(this.url + '/ticket/getbyid/'+id)
  }
  update_status(data):Observable<any>{
    return this.http.post(this.url + '/ticket/updatestatus',data)
  }
  ticketcount():Observable<any>{
    return this.http.get(this.url + '/ticket/getticketcount/'+localStorage.getItem('cid'))
  }
  getdataexcel(data):Observable<any>{
    return this.http.post(this.url + '/ticket/exceldata/'+localStorage.getItem('cid'),data)
  }
  notify(data):Observable<any>{
    return this.http.post(this.url + '/admin/notify',data)
  }
  get_mob_token(id): Observable<any>{
    return this.http.get(this.url + '/house/gethouse/'+ id)
  }
}