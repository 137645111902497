import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  url = environment.url;

  constructor(private http: HttpClient, private router: Router) {
  }

  // productlist(): Observable<any> {
  //   return this.http.get(this.url + '/admin/product/all')
  // }
  // productlistByCountry_subCatId(data): Observable<any> {
  //   return this.http.post(this.url + '/admin/products/byCountry_subCatId', data)
  // }
  // orderlist(): Observable<any> {
  //   return this.http.get(this.url + '/admin/orders/all')
  // }
  // loglist(): Observable<any> {
  //   return this.http.get(this.url + '/admin/log/all')
  // }
  // productlistbyshop(id): Observable<any> {
  //   let data = {
  //     shopId: id
  //   }
  //   return this.http.post(this.url + '/admin/product/byshopId', data)
  // }
  // MultiplefileUploader(images): Observable<any> {
  //   const fd = new FormData();
  //   for (let img of images) {
  //     fd.append('files', img);
  //   }
  //   return this.http.post(this.url + '/admin/multiplefiles', fd);
  // }
  // addproduct(data): Observable<any> {
  //   return this.http.post(this.url + '/admin/product/create', data)
  // }
  // addimages(data): Observable<any> {
  //   return this.http.post(this.url + '/admin/product/updateImages', data)
  // }
  // updateproduct(data): Observable<any> {
  //   return this.http.post(this.url + '/admin/product/update', data)
  // }
  // changeProdStatus(data): Observable<any> {
  //   return this.http.post(this.url + '/admin/product/changeStatus', data)
  // }

  create(data): Observable<any> {
    return this.http.post(this.url + '/task/create/', data)
  }

  getAllTask(): Observable<any> {
    return this.http.get(this.url + '/task/getAllTask')
  }

  deleteTask(id): Observable<any> {
    let data = {
      status: '-1'
    }
    return this.http.post(this.url + '/task/delete/' + id, data)
  }

  updateTask(data) {
    return this.http.post(this.url + '/task/update/', data)
  }


}
