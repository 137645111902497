import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  url = environment.url;
  cid;

  constructor(private http:HttpClient, private router:Router) {
    this.cid = localStorage.getItem('cid');

   }

  questionlist():Observable<any>{
    return this.http.get(this.url + '/question/getallquestion/'+localStorage.getItem('cid'))
  }

  getchecklistbyhouse(data):Observable<any>{
    return this.http.post(this.url + '/checklist/getallchecklistofhouse/'+localStorage.getItem('cid'),data)
  }

  addquestion(data):Observable<any>{
    return this.http.post(this.url + '/question/addquestion/'+ localStorage.getItem('cid'),data)
  }

  addpicture(data):Observable<any>{
    return this.http.post(this.url + '/checklist/addpic',data)
  }

  getanswerbyid(id):Observable<any>{
    return this.http.get(this.url + '/checklist/getanswerbycheckid/'+id)
  }
  getChecklistDetails(id):Observable<any>{
    return this.http.get(this.url + '/checklist/getinfo/'+id)
  }
  getQuestionDetails(id):Observable<any>{
    return this.http.get(this.url + '/question/getquestion/'+id)
  }

  updatequestion(id,data):Observable<any>{
    return this.http.post(this.url + '/question/edit/'+id, data)
  }

  updatesequence(data):Observable<any>{
    return this.http.post(this.url + '/question/update', data)
  }

  deletequestion(id):Observable<any>{
    return this.http.get(this.url + '/question/delete/'+id)
  }


}
