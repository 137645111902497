import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = environment.url;
  cid;

  constructor(private http: HttpClient, private router: Router) {
    this.cid = localStorage.getItem('cid');
    console.log('user service cunstructor');
  }

  userlist(): Observable<any> {
    return this.http.get(this.url + '/user/getAll')
  }
  // salemanagerlist(): Observable<any> {
  //   return this.http.get(this.url + '/saleman/allManagers')
  // }
  // salemanlist(id): Observable<any> {
  //   return this.http.get(this.url + '/saleman/allByManagerId/' + id)
  // }
  // ownerlist(): Observable<any> {
  //   return this.http.get(this.url + '/user/getallowner/' + localStorage.getItem('cid'))
  // }
  // managerlist(): Observable<any> {
  //   return this.http.get(this.url + '/user/getallmanager/' + localStorage.getItem('cid'))
  // }

  // customNotilist(): Observable<any> {
  //   return this.http.get(this.url + '/user/getAllCustomNoti')
  // }

  // appointmentlist(): Observable<any> {
  //   return this.http.get(this.url + '/appointment/all')
  // }

  // servicelist(): Observable<any> {
  //   return this.http.get(this.url + '/service/all')
  // }

  // usercount(): Observable<any> {
  //   return this.http.get(this.url + '/user/getusercount/' + localStorage.getItem('cid'))
  // }

  adduser(data): Observable<any> {
    return this.http.post(this.url + '/user/login/', data)
  }
  // addsaleman(data): Observable<any> {
  //   return this.http.post(this.url + '/saleman/create', data)
  // }

  getUserDetails(id): Observable<any> {
    return this.http.get(this.url + '/user/getuser/' + id)
  }

  updateuser(data): Observable<any> {
    return this.http.post(this.url + '/user/update/', data)
  }

  deleteuser(id): Observable<any> {
    let data = {
      status: '-1'
    }
    return this.http.post(this.url + '/user/delete/' + id, data)
  }
  // deletesaleman(id): Observable<any> {
  //   let data = {
  //     status: '-1'
  //   }
  //   return this.http.post(this.url + '/saleman/update/' + id, data)
  // }
  // updatesaleman(id, data): Observable<any> {
  //   return this.http.post(this.url + '/saleman/update/' + id, data)
  // }

  // updateshopsaleman(id, data): Observable<any> {
  //   return this.http.post(this.url + '/saleman/changeShopSaleman/' + id, data)
  // }

  // shopsalemanhistory(id): Observable<any> {
  //   return this.http.get(this.url + '/saleman/shopHistory/' + id)
  // }

  // shoppackagehistory(id): Observable<any> {
  //   return this.http.get(this.url + '/saleman/shopPackageHistory/' + id)
  // }

  // allpackagehistory(): Observable<any> {
  //   return this.http.get(this.url + '/saleman/allPackageHistory/')
  // }

  // allsaleman(): Observable<any> {
  //   return this.http.get(this.url + '/saleman/all/-1')
  // }

  // activepackagesbymanagerid(id): Observable<any> {
  //   return this.http.get(this.url + '/shopPackage/allActiveByManagerId/' + id)
  // }

  // activepackagesbycountryname(name): Observable<any> {
  //   return this.http.get(this.url + '/shopPackage/allActiveByCountryName/' + name)
  // }

  // assignshoppackage(id, data): Observable<any> {
  //   return this.http.post(this.url + '/saleman/assignShopPackage/' + id, data)
  // }

  // getallshop(): Observable<any> {
  //   return this.http.get(this.url + '/shop/getAll')
  // }

  // getallcountry(): Observable<any> {
  //   return this.http.get(this.url + '/country/getAll')
  // }

  getalladminnotification(): Observable<any> {
    return this.http.get(this.url + '/adminnotification/getAll')
  }

  sendadminnotification(data): Observable<any> {
    return this.http.post(this.url + '/notifications/sendNotification/', data)
  }
  getAllLog(): Observable<any> {
    return this.http.get(this.url + '/activityLog/get')
  }
  getAllNotifications(): Observable<any> {
    return this.http.get(this.url + '/adminNotifications/getAllNotify')
  }

  // shopreviews(): Observable<any> {
  //   return this.http.get(this.url + '/shop/reviews')
  // }

  // getMallSetting(id): Observable<any> {
  //   return this.http.get(this.url + '/admin/mallSetting/byCountryId/' + id)
  // }

  // updateMallSetting(id, data): Observable<any> {
  //   return this.http.post(this.url + '/admin/mallSetting/update/' + id, data)
  // }
  uploadImage(data): Observable<any> {
    return this.http.post(this.url + '/admin/uploadImage', data)
  }

  // getdataexcel(data): Observable<any> {
  //   return this.http.post(this.url + '/user/exceldata/' + localStorage.getItem('cid'), data)
  // }


}
