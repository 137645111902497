import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  url = environment.url;
  cid;

  constructor(private http: HttpClient, private router: Router) {
    this.cid = localStorage.getItem('cid');
  }

  // home(): Observable<any> {
  //   return this.http.get(this.url + '/admin/home')
  // }
  adminlist(): Observable<any> {
    return this.http.get(this.url + '/admin/getalladmin/' + localStorage.getItem('cid'))
  }


  addadmin(data): Observable<any> {
    return this.http.post(this.url + '/admin/register/' + localStorage.getItem('cid'), data)
  }

  getAdmin(): Observable<any> {
    return this.http.get(this.url + '/admin/allAdmin')
  }

  updateAdmin(data): Observable<any> {
    return this.http.post(this.url + '/admin/update/', data)
  }

  delete(id, data): Observable<any> {
    return this.http.post(this.url + '/admin/delete/' + id, data)
  }


  create(data): Observable<any> {
    return this.http.post(this.url + '/admin/create/', data)
  }

  allCounts(): Observable<any> {
    return this.http.get(this.url + '/admin/allCounts')
  }

}
