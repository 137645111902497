import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VariablesService {
  url = environment.url;
  cid;

  constructor(private http:HttpClient, private router:Router) {
    this.cid = localStorage.getItem('cid');
      console.log('user service cunstructor');
   }

  varlist():Observable<any>{
    return this.http.get(this.url + '/admin/variable/all')
  }

  varlistbyid(id):Observable<any>{
    let data ={
      subcatId:id
    }
    console.log(data)
    return this.http.post(this.url + '/admin/variable/bysubcat',data)
  }

  
  
  addvariable(data):Observable<any>{
    return this.http.post(this.url + '/admin/variable/create',data)
  }

  updatevar(data):Observable<any>{
    return this.http.post(this.url + '/admin/variable/update', data)
  }

  deletevar(id):Observable<any>{
    let data = {
      id: id
    }
    return this.http.post(this.url + '/admin/variable/delete',data)
  }


 
}
