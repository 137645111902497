import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from 'app/service/login.service';
import { Router } from '@angular/router';
import { SettingService } from 'app/service/setting.service';
import { environment } from 'environments/environment';
import firebase from 'firebase/app'
import 'firebase/messaging'
import swal from 'sweetalert';
// import { ToastrService } from 'ngx-toastr';
firebase.initializeApp(environment.firebaseConfig)

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  url = environment.url;
  env = environment;

  myForm: FormGroup;
  error = '';

  img: any;
  no_logo = true;
  setting_data = [];
  loading = false;

  formObj = {
    email: '',
    password: '',
    // fcmTokens: {
    fcm: '',
    // }
  }
  fcm = '';
  constructor(
    private service: LoginService,
    private _router: Router,
    private _service: SettingService,
    // private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.myForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.email]),
      'password': new FormControl(null, Validators.required)
    })
    this.getFcm();
  }

  // async getFcm() {

  //   try {
  //   this.fcm = await firebase.messaging().getToken({
  //     vapidKey:
  //       'BGK_99OOPsin5WL9RN1FBtLe0LWpiI0LyUpdwzzMsrBxPyywgIB--y_pO4af3aePWBO93aGqW7547mOKJJAhNLs',
  //   })
  //   this.formObj.fcmTokens.fcmToken = this.fcm;
  //   console.log(this.fcm, 'fcm-==============--=-=-=-=-=--=-=-=-=-=');

  //   } catch (error) {
  //     console.log(error, 'error');
  //     this.fcm = '';
  //   }
  // }

  async getFcm() {
    try {
      // const permission = await Notification.requestPermission();
      // if (permission === 'granted') {
      this.fcm = await firebase.messaging().getToken({
        vapidKey:
          'BGK_99OOPsin5WL9RN1FBtLe0LWpiI0LyUpdwzzMsrBxPyywgIB--y_pO4af3aePWBO93aGqW7547mOKJJAhNLs',
      });
      console.log(this.fcm, 'here is the fcm');

      this.formObj.fcm = this.fcm;
      console.log(this.fcm, 'fcm');
      // } else {
      //   console.log('User has blocked notifications');
      // }
    } catch (error) {
      console.log(error, 'error');
      this.fcm = '';
    }
  }


  onSubmit() {
    if (this.myForm.valid == true) {
      this.formObj.email = this.myForm.value.email;
      this.formObj.password = this.myForm.value.password;
      // this.formObj.fcm = this.myForm.value.this;
      console.log(this.formObj, 'here is the formObj');

      this.loading = true
      this.service.checkuser(this.formObj).subscribe(res => {
        this.loading = false
        console.log(res, 'res here is the res which we console on login page');
        // return;
        if (res['status'] == 1) {
          localStorage.setItem('token', res.jwt)
          console.log(res.jwt, 'here is the res.jwt');

          localStorage.setItem('fcm', this.fcm);
          console.log(this.fcm, 'here is the fcm ');

          localStorage.setItem('user', JSON.stringify(res.data));
          console.log(res.data, 'here is the res.user');
          this._router.navigate(['/dashboard'])
          swal('Login Successfully')

        } else {
          // this.error = 'Your credentials are not Correct';
          swal('Account not found')
        }
      })
    }
    else {
      this.error = 'Fill all the credentials';
      swal('Not all field have been entered')
    }
  }

  // get_logo() {
  //   this._service.setting_list().subscribe(data => {
  //     this.setting_data = data;
  //     if (data.length > 0) {
  //       if (data[0].logo == null || data[0].logo == '') {
  //         this.no_logo = true;
  //       } else {

  //         this.img = this.url + '/' + data[0].logo;
  //         this.no_logo = false
  //       }
  //     } else {
  //       this.no_logo = true;
  //     }

  //   })
  // }

}
